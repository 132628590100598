import Cookies from 'universal-cookie'
import { injectable } from 'inversify'
import { ILoginModel } from '../../model/LoginModel'

const cookies = new Cookies()

export interface IAuthService {
    isAuthenticated(): boolean
    getToken(): string
    getRefresh(): string
    setToken(token: string)
    setRefreshToken(token: string)
    signInAndReload(model: ILoginModel): void
    signIn(model: ILoginModel, postLogin: () => void): void
    signOut(postLogout: () => void): void
    signOutAndReload(): void
    restart(): void
}


@injectable()
export class AuthService implements IAuthService {
    authKey: string = `logged`
    refreshKey: string = `${process.env.REACT_APP_NAME}_refresh`

    isAuthenticated(): boolean {
        return cookies.get(this.authKey) != null
    }

    getToken(): string {
        return cookies.get(this.authKey)
    }

    getRefresh(): string {
        return cookies.get(this.refreshKey)
    }

    setToken(token: string) {
        cookies.set(this.authKey, token, { path: '/',  sameSite:'strict',secure:true })
    }
    setRefreshToken(token: string) {
        //cookies.set(this.refreshKey, token, { path: '/', sameSite: 'strict', secure: true})
    }

    signInAndReload(model: ILoginModel): void {
        this.signIn(model, () => { this.restart() })
    }

    signIn(model: ILoginModel, postLogin: () => void): void {
        this.setToken("1")
        //this.setRefreshToken(model.UserName)
        postLogin()
    }

    signOut(postLogout: () => void): void {
        cookies.remove(this.authKey)
        cookies.remove(this.refreshKey)
        if (postLogout != null) postLogout()
    }

    signOutAndReload(): void {
        this.signOut(() => { this.restart() })
    }

    restart(): void {
        window.location.href = '/'
    }
}
